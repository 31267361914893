import {useMemo} from 'react';
import {useQuery} from 'react-query';

import {getTipcoDoor} from 'utils/horizon-api-client';
import {centsToDollars} from "../utils/numbers";

export function useTipcoDoor({id = null}) {
    const {data, status, refetch} = useQuery(['tipco_door', id], fetchById, {enabled: id !== null});
    const doorData = useMemo(() => mapDoorData(data), [data]);

    async function fetchById(key, id) {
        return await getTipcoDoor({id: id});
    }

    function mapDoorData(data) {
        if (typeof data === 'undefined')
            return {
                tipco_calculator_override: {},
                prehang: {
                    prehang_override: {},
                },
            };

        return {
            door_class: 'TIPCO',
            id: data.id,
            quote_id: data.quote_id,
            door_type: data.door_type,
            part_number: data.part_number,
            height: data.height || 0,
            actual_door_height: data.actual_door_height || 0,
            width: data.width || 0,
            width_pair: data.width_pair || 0,
            thickness: data.thickness || 0,
            prehang_enabled: data.prehang_enabled || false,
            simple_description: data.simple_description || '',
            full_description: data.full_description || '',

            leaf_type: data.tipco_leaf?.code || '',
            jamb_type: data.tipco_jamb?.code || '',
            cost_price_with_phang: (Number(data.cost_price) + Number(data.phang_cost_price)).toFixed(2) || 0,
            sell_price_with_phang: (Number(data.sell_price) + Number(data.phang_sell_price)).toFixed(2) || 0,

            serial: data.serial || '',
            reference: data.reference || '',
            position: data.position || '',
            number_of_clashings: data.number_of_clashings || 0,
            notes: data.notes || '',

            sell_price: data.sell_price || 0.0,
            cost_price: data.cost_price || 0.0,
            phang_cost_price: data.phang_cost_price || 0.0,
            phang_sell_price: data.phang_sell_price || 0.0,

            parent_id: data.parent_id || '',

            bifold_assembly_id: data.bifold_assembly_id,
            door_jamb_handing_id: data.door_jamb_handing_id,
            tipco_cropping_dimension_id: data.tipco_cropping_dimension_id,
            tipco_door_spec_id: data.tipco_door_spec_id,
            tipco_humes_designation_id: data.tipco_humes_designation_id,
            tipco_hung_type_id: data.tipco_hung_type_id,
            tipco_jamb_id: data.tipco_jamb_id,
            tipco_leaf_id: data.tipco_leaf_id,
            door_door_extras: doorDoorExtraArray(),
            door_prehang_extras: doorPrehangExtraArray(),
            vision_panel_ids: visionPanelsArray(),

            tipco_calculator_override: {
                id: data.tipco_calculator_override.id,
                cost_price: data.tipco_calculator_override.cost_price || 0.0,
                sell_price: data.tipco_calculator_override.sell_price || 0.0,
            },

            calculated_sell_price: data.calculated_sell_price || 0.0,

            prehang: {
                prehang_details: data.prehang.prehang_details,
                hinge_face_gib_lining_thickness: data.prehang.hinge_face_gib_lining_thickness || 0,
                non_hinge_face_gib_lining_thickness: data.prehang.non_hinge_face_gib_lining_thickness || 0,
                door_jamb_floor_clearance: data.prehang.door_jamb_floor_clearance || 0,
                door_jamb_timber_id: data.prehang.door_jamb_timber_id || '',
                door_jamb_facing_id: data.prehang.door_jamb_facing_id || '',
                door_jamb_handing_id: data.prehang.door_jamb_handing_id || '',
                door_jamb_profile_id: data.prehang.door_jamb_profile_id || '',
                door_jamb_sill_id: data.prehang.door_jamb_sill_id || '',
                ph_galv_steel_frame:
                    data.prehang.ph_galv_steel_frame == null ? null : String(data.prehang.ph_galv_steel_frame) || 'false',
                discount_factor_phang: data.prehang.discount_factor_phang || 0.0,
                number_of_hinges: data.prehang.number_of_hinges || 0,
                standard_jamb: data.prehang.standard_jamb == null ? null : String(data.prehang.standard_jamb) || 'false',
                cropping_cyl_or_mortice: data.prehang.cropping_cyl_or_mortice || '', // not sure if used
                handing_large_leaf: data.prehang.handing_large_leaf || '', // not sure if used
                jamb_thickness: data.prehang.jamb_thickness || 0,
                manual_machining_code: String(data.prehang.manual_machining_code) || '',
                number_of_sides: data.prehang.number_of_sides || 0,
                wall_stud_width: data.prehang.wall_stud_width || 0,
                manual_reveal_hinge_face: data.prehang.manual_reveal_hinge_face || 0,
                manual_reveal_non_hinge_face: data.prehang.manual_reveal_non_hinge_face || 0,
                manual_fj_cl: data.prehang.manual_fj_cl || '',
                manual_hinge_part_code: data.prehang.manual_hinge_part_code || '',
                manual_door_stop_part_code: data.prehang.manual_door_stop_part_code || '',
                prehang_override: {
                    sell_price: data.prehang.prehang_override.sell_price || null,
                    hinge_part_code: data.prehang.prehang_override.hinge_part_code || '',
                    hinge_quantity: data.prehang.prehang_override.hinge_quantity || null,
                    hinge_part_price:
                        centsToDollars(data.prehang.prehang_override.hinge_part_price) > 0
                            ? centsToDollars(data.prehang.prehang_override.hinge_part_price)
                            : null,
                },
            },
        };
    }

    function doorDoorExtraArray() {
        if (typeof data === 'undefined' || data.door_door_extras.length < 1) return [];

        return data.door_door_extras.map(obj => {
            return {
                id: obj.id,
                door_extra_id: obj.door_extra_id,
                cost: (obj.cost_cents / 100).toFixed(2),
                sell: (obj.sell_cents / 100).toFixed(2),
                qty: obj.qty,
            };
        });
    }

    function visionPanelsArray() {
        if (typeof data === 'undefined' || data.vision_panels.length < 1) return [];

        return data.vision_panels.map(panel => {
            return panel.id;
        });
    }

    function doorPrehangExtraArray() {
        if (typeof data === 'undefined' || data.door_prehang_extras.length < 1) return [];

        return data.door_prehang_extras.map(obj => {
            return {
                id: obj.id,
                door_extra_id: obj.door_extra_id,
                cost: (obj.cost_cents / 100).toFixed(2),
                sell: (obj.sell_cents / 100).toFixed(2),
                qty: obj.qty,
            };
        });
    }

    return {
        data: doorData,
        mapTipcoDoorData: mapDoorData,
        status,
        refetch,
    };
}
