import {useMemo} from 'react';

import {useQuery} from 'react-query';

import {getFireDoor} from 'utils/horizon-api-client';
import {find} from 'lodash';
import {useFireConfigOptions} from './useFireConfigOptions';
import {centsToDollars, roundNumber} from '../utils/numbers';

export function useFireDoor({id = null}) {
    const {data, status, refetch} = useQuery(['fire_door', id], fetchById, {enabled: id !== null});
    const {fireCores, skinCodes, doorJambProfiles} = useFireConfigOptions();
    const doorData = useMemo(() => mapDoorData(data), [data]);

    async function fetchById(key, id) {
        return await getFireDoor({id: id});
    }

    function visionPanelsArray() {
        if (typeof data === 'undefined' || data.fire_door_vision_panels.length < 1) return [];

        return data.fire_door_vision_panels.map(p => {
            return {
                id: p.id,
                fire_vision_panel_id: p.fire_vision_panel_id,
                fire_door_id: p.fire_door_id,
                height: p.height,
                width: p.width,
            };
        });
    }

    function doorDoorExtraArray() {
        if (typeof data === 'undefined' || data.door_door_extras.length < 1) return [];

        return data.door_door_extras.map(obj => {
            return {
                id: obj.id,
                door_extra_id: obj.door_extra_id,
                cost: (obj.cost_cents / 100).toFixed(2),
                sell: (obj.sell_cents / 100).toFixed(2),
                qty: obj.qty,
            };
        });
    }

    function mapDoorData(data) {
        if (typeof data === 'undefined')
            return {
                fire_calculator_override: {},
                fire_prehang: {
                    fire_prehang_override: {},
                },
            };

        const skinCode = find(skinCodes, skinCode => data.skin_code_id === skinCode.value);
        const coreType = find(fireCores, core => data.fire_core_id === core.value);
        const jambType = find(doorJambProfiles, djp => {
            return data.fire_prehang && data.fire_prehang.door_jamb_profile_id === djp.value;
        });

        return {
            door_class: 'FIRE',
            id: data.id,
            quote_id: data.quote_id,
            door_type: data.door_type,
            height: data.height || 0,
            width: data.width || 0,
            width_pair: data.width_pair || 0,
            thickness: data.thickness || 0,
            serial: data.serial || '',
            reference: data.reference || '',
            position: data.position || '',
            notes: data.notes || '',
            parent_id: data.parent_id || '',
            discount_factor: data.discount_factor || 0,
            door_details: data.door_details || '',
            extra_skin_quantity: data.extra_skin_quantity || 0,
            frr: data.frr || '',
            manufacturer: data.manufacturer || '',

            ph: data.is_dlo ? 'D.L.O.' : data.fire_prehang.details_overview,
            jamb_type: jambType && jambType.label, // fire_prehang.door_jamb_profile_id
            leaf_type: data.door_type,
            skin_shortcode: skinCode ? skinCode.shortcode : '',
            core_shortcode: coreType ? coreType.shortcode : '',
            clashing_detail: data.clashing_detail || '',
            cost_price_with_phang: (Number(data.cost_price) + Number(data.phang_cost_price)).toFixed(2) || 0,
            sell_price_with_phang: (Number(data.sell_price) + Number(data.phang_sell_price)).toFixed(2) || 0,

            cost_price: data.cost_price || 0,
            sell_price: data.sell_price || 0,
            calculated_sell_price: data.calculated_sell_price || 0,
            manual_sell_price: data.manual_sell_price || 0,
            phang_cost_price: data.phang_cost_price || 0,
            phang_sell_price: data.phang_sell_price || 0,

            extra_machining_a_qty: data.extra_machining_a_qty || 1,
            extra_machining_b_qty: data.extra_machining_b_qty || 1,
            extra_machining_c_qty: data.extra_machining_c_qty || 1,
            extra_machining_a_id: data.extra_machining_a_id || '',
            extra_machining_b_id: data.extra_machining_b_id || '',
            extra_machining_c_id: data.extra_machining_c_id || '',
            extra_skin_id: data.extra_skin_id || '',
            fire_core_id: data.fire_core_id || '',
            fire_door_clashing_strip_id: data.fire_door_clashing_strip_id || '',
            fire_door_jamb_cont_id: data.fire_door_jamb_cont_id || '',
            fire_door_spec_id: data.fire_door_spec_id || '',
            fire_meeting_stile_id: data.fire_meeting_stile_id || '',
            skin_code_id: data.skin_code_id || '',
            prehang_enabled: data.prehang_enabled || false,
            door_door_extras: doorDoorExtraArray(),
            fire_door_vision_panels: visionPanelsArray(),

            calculator: {
                framing_timber: {
                    part_code: data.calculator.framing_timber.part_code,
                    part_price: centsToDollars(data.calculator.framing_timber.part_price),
                    quantity: roundNumber(data.calculator.framing_timber.quantity),
                },
                core: {
                    part_code: data.calculator.core.part_code,
                    part_price: centsToDollars(data.calculator.core.part_price),
                    quantity: roundNumber(data.calculator.core.quantity),
                },
                core_kit: {
                    part_code: data.calculator.core_kit.part_code,
                    part_price: centsToDollars(data.calculator.core_kit.part_price),
                    quantity: roundNumber(data.calculator.core_kit.quantity),
                },
                skin: {
                    part_code: data.calculator.skin.part_code,
                    part_price: centsToDollars(data.calculator.skin.part_price),
                    quantity: roundNumber(data.calculator.skin.quantity),
                },
                extra_skin: {
                    part_code: data.calculator.extra_skin.part_code,
                    part_price: centsToDollars(data.calculator.extra_skin.part_price),
                    quantity: roundNumber(data.calculator.extra_skin.quantity),
                },
                jamb: {
                    part_code: data.calculator.jamb.part_code,
                    part_price: centsToDollars(data.calculator.jamb.part_price),
                    quantity: roundNumber(data.calculator.jamb.quantity),
                },
                hinge: {
                    part_code: data.calculator.hinge.part_code,
                    part_price: centsToDollars(data.calculator.hinge.part_price),
                    quantity: roundNumber(data.calculator.hinge.quantity),
                },
            },

            fire_prehang: {
                id: data.fire_prehang.id || '',
                hinge_face_gib_lining_thickness: data.fire_prehang.hinge_face_gib_lining_thickness || 0,
                non_hinge_face_gib_lining_thickness: data.fire_prehang.non_hinge_face_gib_lining_thickness || 0,
                wall_stud_width: data.fire_prehang.wall_stud_width || 0,
                door_jamb_profile_id: data.fire_prehang.door_jamb_profile_id || '',
                fire_door_id: data.fire_prehang.fire_door_id || '',
                fire_door_jamb_handing_id: data.fire_prehang.fire_door_jamb_handing_id || '',
                fire_door_jamb_timber_id: data.fire_prehang.fire_door_jamb_timber_id || '',
                door_jamb_floor_clearance: data.fire_prehang.door_jamb_floor_clearance || 0,
                fire_prehang_override: {
                    hinge_part_code: data.fire_prehang.fire_prehang_override.hinge_part_code,
                    hinge_part_price: centsToDollars(data.fire_prehang.fire_prehang_override.hinge_part_price),
                    hinge_quantity: data.fire_prehang.fire_prehang_override.hinge_quantity,
                },
            },
        };
    }

    return {
        data: doorData,
        mapFireDoorData: mapDoorData,
        status,
        refetch,
    };
}
