import React from 'react';
import {useFormikContext} from 'formik';

import OverridableField from 'components/DoorForm/OverridableField';

function PricingOverview({doorData}) {
    const {values} = useFormikContext();

    return (
        <div className="row">
            <div className="col-md-6">
                <p>
                    <span>Door Cost: ${doorData.cost_price}</span>
                    <br/>
                    <OverridableField
                        label="Door Sell Price: $"
                        formFieldAccessor={'manual_sell_price'}
                        fieldType={'number'}
                        overrideValue={values.manual_sell_price}
                        calculatedValue={doorData.calculated_sell_price}
                    />
                    <span>Prehang Cost: ${doorData.phang_cost_price}</span>
                    <br/>
                    <span>
            <OverridableField
                label="Prehang Sell Price: $"
                formFieldAccessor={'prehang.prehang_override.sell_price'}
                fieldType={'number'}
                overrideValue={values.prehang.prehang_override.sell_price}
                calculatedValue={doorData.phang_sell_price}
            />
          </span>
                </p>
            </div>
            <div className="col-md-6">
                <p>
                    <span>Total Cost: ${doorData.cost_price_with_phang}</span><br/>
                    <span>Total Sell: ${doorData.sell_price_with_phang}</span>
                </p>
            </div>
        </div>
    );
}

export default PricingOverview;
