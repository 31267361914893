import {useMemo} from 'react';
import {useQuery} from 'react-query';

import {getDoor} from 'utils/horizon-api-client';
import {useDoorConfigOptions} from './useDoorConfigOptions';
import {find} from 'lodash';
import {centsToDollars, roundNumber} from '../utils/numbers';

export function useDoor({id = null}) {
    const {data, status, refetch} = useQuery(['door', id], fetchDoorById, {enabled: id !== null});
    const {coreTypes, meetingStiles, clashingTimbers, skinCodes, doorJambTimbers} = useDoorConfigOptions();

    const doorData = useMemo(() => mapDoorData(data), [data]);

    async function fetchDoorById(key, id) {
        if (typeof id === 'undefined') return {};

        const response = await getDoor({id: id});
        return response.data;
    }

    function mapDoorData(data) {
        if (typeof data === 'undefined')
            return {
                prehang: {
                    prehang_override: {},
                },
            };

        const skinCode = find(skinCodes, skinCode => data.skin_code_id === skinCode.value);
        const coreType = find(coreTypes, core => data.core_type_id === core.value);
        const meetingStile = find(meetingStiles, stile => data.meeting_stile_id === stile.value);
        const clashing = find(clashingTimbers, timber => data.clashing_timber_id === timber.value);
        const clashingDetail = `E${data.clashing_1_thickness}/${data.clashing_2_thickness} 
      ${meetingStile ? meetingStile.shortcode : ''}
      ${clashing ? clashing.shortcode : ''}
    `;
        const jambType = find(doorJambTimbers, djt => {
            return data.prehang && data.prehang.door_jamb_timber_id === djt.value;
        });

        return {
            door_class: 'Standard',
            id: data.id,
            quote_id: data.quote_id,
            door_type: data.door_type,
            height: data.height || 0,
            width: data.width || 0,
            width_pair: data.width_pair || 0,
            thickness: data.thickness || 0,
            number_of_clashings: data.number_of_clashings || 0,
            discount_factor: data.discount_factor || 0,
            contribution_factor: data.contribution_factor || 0,
            clashing_1_thickness: data.clashing_1_thickness || 0,
            clashing_2_thickness: data.clashing_2_thickness || 0,

            jamb_type: (jambType && jambType.label) || '',
            leaf_type: data.door_type,
            skin_shortcode: skinCode ? skinCode.shortcode : '',
            core_shortcode: coreType ? coreType.shortcode : '',
            clashing_detail: clashingDetail,
            cost_price_with_phang: (Number(data.cost_price) + Number(data.phang_cost_price)).toFixed(2) || 0,
            sell_price_with_phang: (Number(data.sell_price) + Number(data.phang_sell_price)).toFixed(2) || 0,
            ph: '',

            cost_price: data.cost_price || 0,
            sell_price: data.sell_price || 0,
            calculated_sell_price: data.calculated_sell_price || 0,
            manual_sell_price: data.manual_sell_price || 0,
            phang_cost_price: data.phang_cost_price || 0,
            phang_sell_price: data.phang_sell_price || 0,
            core_type_id: data.core_type_id || '',
            skin_code_id: data.skin_code_id || '',
            extra_skin_code_id: data.extra_skin_code_id || '',
            clashing_timber_id: data.clashing_timber_id || '',
            meeting_stile_id: data.meeting_stile_id || '',

            door_stop_part_code: data.door_stop_part_code || '',
            isChild: data.parent_id,
            prehang_enabled: data.prehang_enabled,
            is_slider: data.is_slider,
            assigned_slider_type: data.assigned_slider_type,
            door_details: data.door_details || '',
            notes: data.notes || '',
            serial: data.serial || '',
            reference: data.reference || '',
            calculator: {
                framing_timber: {
                    part_code: data.calculator.framing_timber.part_code,
                    part_price: centsToDollars(data.calculator.framing_timber.part_price),
                    quantity: roundNumber(data.calculator.framing_timber.quantity),
                },
                core: {
                    part_code: data.calculator.core.part_code,
                    part_price: centsToDollars(data.calculator.core.part_price),
                    quantity: roundNumber(data.calculator.core.quantity),
                },
                lock_blocks: {
                    part_code: data.calculator.lock_blocks.part_code,
                    part_price: centsToDollars(data.calculator.lock_blocks.part_price),
                    quantity: roundNumber(data.calculator.lock_blocks.quantity),
                },
                skins: {
                    part_code: data.calculator.skins.part_code,
                    part_price: centsToDollars(data.calculator.skins.part_price),
                    quantity: roundNumber(data.calculator.skins.quantity),
                },
                extra_skins: {
                    part_code: data.calculator.extra_skins.part_code,
                    part_price: centsToDollars(data.calculator.extra_skins.part_price),
                    quantity: roundNumber(data.calculator.extra_skins.quantity),
                },
                clashing_strip_1: {
                    part_code: data.calculator.clashing_strip_1.part_code,
                    part_price: centsToDollars(data.calculator.clashing_strip_1.part_price),
                    quantity: roundNumber(data.calculator.clashing_strip_1.quantity),
                },
                clashing_strip_2: {
                    part_code: data.calculator.clashing_strip_2.part_code,
                    part_price: centsToDollars(data.calculator.clashing_strip_2.part_price),
                    quantity: roundNumber(data.calculator.clashing_strip_2.quantity),
                },
            },
            calculator_override: {
                extra_skins_part_price: roundNumber(data.calculator_override.extra_skins_part_price),
                extra_skins_quantity: roundNumber(data.calculator_override.extra_skins_quantity),
                extra_skins_thickness: data.calculator_override.extra_skins_thickness,
                skins_part_code: data.calculator_override.skins_part_code,
                skins_part_price: roundNumber(data.calculator_override.skins_part_price),
                skins_quantity: roundNumber(data.calculator_override.skins_quantity),
            },
            prehang: {
                prehang_details: data.prehang.prehang_details,
                hinge_face_gib_lining_thickness: data.prehang.hinge_face_gib_lining_thickness || 0,
                non_hinge_face_gib_lining_thickness: data.prehang.non_hinge_face_gib_lining_thickness || 0,
                door_jamb_floor_clearance: data.prehang.door_jamb_floor_clearance || 0,
                door_jamb_timber_id: data.prehang.door_jamb_timber_id || '',
                door_jamb_facing_id: data.prehang.door_jamb_facing_id || '',
                door_jamb_handing_id: data.prehang.door_jamb_handing_id || '',
                door_jamb_profile_id: data.prehang.door_jamb_profile_id || '',
                door_jamb_sill_id: data.prehang.door_jamb_sill_id || '',
                ph_galv_steel_frame:
                    data.prehang.ph_galv_steel_frame == null ? null : String(data.prehang.ph_galv_steel_frame) || 'false',
                discount_factor_phang: data.prehang.discount_factor_phang || 0.0,
                number_of_hinges: data.prehang.number_of_hinges || 0,
                standard_jamb: data.prehang.standard_jamb == null ? null : String(data.prehang.standard_jamb) || 'false',
                cropping_cyl_or_mortice: data.prehang.cropping_cyl_or_mortice || '', // not sure if used
                handing_large_leaf: data.prehang.handing_large_leaf || '', // not sure if used
                jamb_thickness: data.prehang.jamb_thickness || 0,
                manual_machining_code: String(data.prehang.manual_machining_code) || '',
                number_of_sides: data.prehang.number_of_sides || 0,
                wall_stud_width: data.prehang.wall_stud_width || 0,
                manual_reveal_hinge_face: data.prehang.manual_reveal_hinge_face || 0,
                manual_reveal_non_hinge_face: data.prehang.manual_reveal_non_hinge_face || 0,
                manual_fj_cl: data.prehang.manual_fj_cl || '',
                manual_hinge_part_code: data.prehang.manual_hinge_part_code || '',
                manual_door_stop_part_code: data.prehang.manual_door_stop_part_code || '',
                hinge_part_code: data.prehang.hinge_part_code || '',
                hinge_quantity: data.prehang.hinge_quantity || 0.0,
                hinge_part_price: centsToDollars(data.prehang.hinge_part_price) || null,
                prehang_override: {
                    sell_price: data.prehang.prehang_override.sell_price || null,
                    hinge_part_code: data.prehang.prehang_override.hinge_part_code || '',
                    hinge_quantity: data.prehang.prehang_override.hinge_quantity || null,
                    hinge_part_price:
                        centsToDollars(data.prehang.prehang_override.hinge_part_price) > 0
                            ? centsToDollars(data.prehang.prehang_override.hinge_part_price)
                            : null,
                },
            },
            sliders: slidersArray(),
            vision_panel_ids: visionPanelsArray(),
            grille_opening_ids: grilleOpeningArray(),
            resorcinol_glue_ids: resorcinolGlueArray(),
            door_door_extras: doorDoorExtraArray(),
            door_prehang_extras: doorPrehangExtraArray(),
        };
    }

    function visionPanelsArray() {
        if (typeof data === 'undefined' || data.vision_panels.length < 1) return [];

        return data.vision_panels.map(panel => {
            return panel.id;
        });
    }

    function slidersArray() {
        if (typeof data === 'undefined' || data.sliders.length < 1) return [];

        return data.sliders.map(obj => {
            return {
                id: obj.id,
                serial: obj.serial,
            };
        });
    }

    function grilleOpeningArray() {
        if (typeof data === 'undefined' || data.grille_openings.length < 1) return [];

        return data.grille_openings.map(obj => {
            return obj.id;
        });
    }

    function resorcinolGlueArray() {
        if (typeof data === 'undefined' || data.resorcinol_glues.length < 1) return [];

        return data.resorcinol_glues.map(obj => {
            return obj.id;
        });
    }

    function doorDoorExtraArray() {
        if (typeof data === 'undefined' || data.door_door_extras.length < 1) return [];

        return data.door_door_extras.map(obj => {
            return {
                id: obj.id,
                door_extra_id: obj.door_extra_id,
                cost: (obj.cost_cents / 100).toFixed(2),
                sell: (obj.sell_cents / 100).toFixed(2),
                qty: obj.qty,
            };
        });
    }

    function doorPrehangExtraArray() {
        if (typeof data === 'undefined' || data.door_prehang_extras.length < 1) return [];

        return data.door_prehang_extras.map(obj => {
            return {
                id: obj.id,
                door_extra_id: obj.door_extra_id,
                cost: (obj.cost_cents / 100).toFixed(2),
                sell: (obj.sell_cents / 100).toFixed(2),
                qty: obj.qty,
            };
        });
    }

    return {
        data: doorData,
        mapDoorData,
        status,
        refetch,
    };
}
